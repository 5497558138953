import { Outlet } from '@remix-run/react'
import { Header } from '~/components/ui/header'
import { Footer } from '~/components/ui/footer'

export default function Layout() {
  return (
    <div className="container mx-auto flex min-h-screen flex-col justify-between space-y-6 md:mt-4 md:space-y-12 lg:space-y-24">
      <div className="md:space-y-6 lg:space-y-12">
        <Header />
        <div className="space-y-16">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}
